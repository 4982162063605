/* colors being used:
subtext - h6 tags #9899ac
background and navbar color - #5fdedc in hex or rgba(95, 222, 220, 1) in rgba
button color - #e96c4e
*/
/* fonts used: 
font-family: "Averia Serif Libre", cursive;
*/
/*main style is coming from react-bootstrap npm package */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Averia Serif Libre", cursive;
  background: fixed
    linear-gradient(to top, rgba(95, 222, 220, 0.2), rgba(95, 222, 220, 0));
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
}

.example {
  border: none;
  background: none;
  font-style: italic;
}

.error {
  color: red;
  font-size: large;
}

.success {
  color: green;
  font-size: large;
}

h6 {
  color: #9899ac;
}

.logo-font {
  font-family: "Averia Serif Libre", cursive;
}

.attributeCards {
  border: none;
  height: 100%;
}
.attributeCards:hover {
  transition: ease-in-out 700ms;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar {
  background: linear-gradient(to right, #5fdedc, rgba(255, 255, 255, 1));
}

.btn {
  border-color: #e96c4e;
  background-color: #e96c4e;
  color: #fff;
}

.btn:focus {
  background-color: #bf5f47;
  border-color: #bf5f47;
}

.btn-check:checked + .btn-primary,
.btn:hover {
  border-color: #bf5f47;
  background-color: #bf5f47;
}

.nav:hover {
  color: #e9c46a;
}

.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  color: #000000;
}

a {
  color: #000000;
  text-decoration: none;
}

/* a:link {
  color: #000000;
  text-decoration: none;
} */

.fc-daygrid-event.fc-event-end {
  cursor: pointer;
}

a:hover {
  color: #bf5f47;
}

.shortPageContainer {
  min-height: calc(100vh - 251px);
}

.tuitionDetailsBox {
  padding-top: 100px;
}

.badgeWrapper {
  margin-top: -20px;
}

.tuitionCards {
  background-color: rgba(0, 0, 0, 0.12);
  color: #000;
  border: none;
  height: 100%;
}

.card-img-overlay {
  color: #fff;
  width: 25%;
}

.custom-shape-divider-bottom-1629199231 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1629199231 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.5px);
  height: 100%;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1629199231 .shape-fill {
  fill: #ffffff;
}

.imageName {
  position: relative;
  bottom: 40px;
}

.list-group-item,
.list-group {
  background-color: rgba(255, 255, 255, 0);
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0px;
}
.videoWrapper iframe {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}

.aboutMeImagesWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0px;
}

.camilleImg {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  width: 50%;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
.youngCamille1Img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  width: 30%;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.youngCamille2Img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  width: 50%;
  top: 0;
  right: 30%;
}
/* .tuitionCards:hover {
  transition: ease-in-out 700ms;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.accordion-button {
  background-color: rgba(255, 255, 255, 0);
}

.accordion-button:not(.collapsed) {
  background-color: rgba(95, 222, 220, 0.2);
  color: #000;
}
.accordion-button:focus {
  background-color: #5fdedc;
  color: black;
  border-color: rgba(95, 222, 220, 0.1);
}

.adminPageTabs {
  color: red;
}

.exampleImgWrapper {
  display: flex;
  place-items: center;
}

.notFoundImage {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .shortPageContainer {
    min-height: calc(100vh - 235px);
  }
}

@media only screen and (max-width: 575px) {
  .badgeWrapper {
    margin-top: -10px;
  }
}
